import { useCallback, useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { OfferStates, useRestartOnboarding } from '@genoa/middle-end'
import { CO_RESUME_ONBOARDING_STEPS, deepReplaceContent, RESUME_ONBOARDING_STEPS } from '@genoa/screen-content'

import { useAuthState } from '../../../../contexts'
import { useContentOverhaul } from '../../../../hooks'
import { useHandleGetOnboardingStatus, useOnboardingStatus } from '../../../../hooks/flex2/onboarding-status'
import { PrefillRedirects, usePrefills } from '../../../../hooks/prefills'
import { useOfferState } from '../../../../modules/flex2/use-state'
import { useAnalytics, useLogger } from '../../../../providers'
import { useUserAccount } from '../../../../providers'
import { ResumeOnboardingToStep } from './ResumeOnboardingToStep'

type ResumeOnboardingToStepContainerProps = {
  analyticsScreenName: Analytics.Screens
  goToFallback: () => void
  prefillRedirects?: PrefillRedirects
  resumeState?: string
  handleIncomeVerification: () => void
}

export const ResumeOnboardingToStepContainer = (props: ResumeOnboardingToStepContainerProps) => {
  const logger = useLogger('ResumeOnboardingToStepContainer')
  const analytics = useAnalytics()
  const { loadingPropertyPrefills } = usePrefills()
  const isResumeExpired = props.resumeState === OfferStates.EXPIRED.toString()
  const offerState = useOfferState()

  const { handleResumeOnboarding, onboardingSteps } = useOnboardingStatus(isResumeExpired)
  const { userAccount } = useUserAccount()

  const { handleGetOnboardingStatus } = useHandleGetOnboardingStatus()
  const [{ loading: loadingRestart }, restartOnboarding] = useRestartOnboarding()
  const { user } = useAuthState()

  const { onboardingEnabled } = useContentOverhaul()

  useEffect(() => {
    analytics.logScreenView(props.analyticsScreenName, { resume_to_step: 'enabled', state: props.resumeState })
  }, [])

  const handleOnResume = async () => {
    analytics.logEvent(Analytics.Events.RESUME_ONBOARDING_RESUME_CTA_CLICKED, { state: props.resumeState })
    if (isResumeExpired && user?.uid) {
      try {
        await restartOnboarding({ customerPublicId: user.uid })
        await handleGetOnboardingStatus(user.uid)
      } catch (error: any) {
        logger.error('Unhandled error during restart expired onboarding', `error: ${error.message}`)
      }
      return
    }

    if (offerState.offer && offerState.offer.offer_state === OfferStates.PENDING_EVALUATE) {
      return props.handleIncomeVerification()
    }

    handleResumeOnboarding(props.goToFallback)
  }

  const handleOnStepClicked = useCallback((step: string) => {
    analytics.logEvent(Analytics.Events.RESUME_STEP_CLICKED, { step })
  }, [])

  const stepContents = onboardingEnabled ? CO_RESUME_ONBOARDING_STEPS : RESUME_ONBOARDING_STEPS
  const content = deepReplaceContent(stepContents, { name: userAccount.firstName || '' })
  const body = isResumeExpired ? content.BODY_ITS_BEEN_A_WHILE : content.BODY

  return (
    <ResumeOnboardingToStep
      onboardingSteps={onboardingSteps.filter((step) => !step.skipDisplay)}
      onResume={handleOnResume}
      customerName={userAccount.firstName || ''}
      loading={loadingPropertyPrefills || loadingRestart}
      onStepClicked={handleOnStepClicked}
      header={content.HEADER}
      body={body}
      cta={content.CTA}
    />
  )
}
